import {Alpine} from '../../vendor/livewire/livewire/dist/livewire.esm';

import timeout from '@victoryoalli/alpinejs-timeout';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import AlpineFloatingUI from '@awcodes/alpine-floating-ui'
import Clipboard from '@ryangjchandler/alpine-clipboard';

Alpine.plugin(timeout);
Alpine.plugin(Tooltip);
Alpine.plugin(AlpineFloatingUI)
Alpine.plugin(Clipboard);
